import React, { memo } from 'react';
import { Button, Spin } from 'antd';

const QRSbpModalFile = ({ qr,link }) => {
 
  return (
    <div
      className='file-wrapper'
      style={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: '200px',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '2rem',
      }}
    >
      {qr ? (
        <img src={qr} alt='qr-code' width='200px' />
      ) : (
        <div className='file-loader'>
          <Spin size='large' />
        </div>
      )}
      {link && (
        <Button href={link} type='primary'>
          Оплатить
        </Button>
      )}
    </div>
  );
};

export default memo(QRSbpModalFile);
